$(document).ready(function () {

    $('.btn-more').on('click', function () {
        var href = $(this).attr('href');

        $('#result-modal .modal-content').load(href, function (response, status, xhr) {
            console.log(response, status, xhr);
            if (status === "success") {
                $('#result-modal').modal('show');
                $(this).html(xhr.responseText); //
            }
        });
        return false;
    });
});