/**
 * Created by Darek on 2016-12-07.
 */
$(document).ready(function () {
    var options = {
        scaleMinSpace: 60,
        seriesBarDistance: 11,
        reverseData: true,
        horizontalBars: true,
        height: (typeof full_height === "undefined" ? 0 : height_),
        axisX: {
            offset: 20,
            scaleMinSpace: 50,

        },
        axisY: {
            offset: 150,
            scaleMinSpace: 60
        },
        plugins: [
            Chartist.plugins.legend({
                legendNames: (typeof years === "undefined" ? [] : years)
            })
        ]
    };

    var responsiveOptions = [
        ['screen and (min-width: 641px) and (max-width: 1024px)', {
            seriesBarDistance: 10,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value;
                }
            }
        }],
        ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
                labelInterpolationFnc: function (value) {
                    return value[0];
                }
            }
        }]
    ];

    if (typeof data !== "undefined") {
        new Chartist.Bar('.ct-chart', data, options, responsiveOptions);
    }
});